<!--
 * @Descripttion:
 * @version:
 * @Author: 郭梦霞
 * @Date: 2023-11-18 15:19:05
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-05-06 09:38:11
-->
<template>
  <div class="container-warp">
    <div class="exam-content">
      <div class="empty-box" v-if="personExamList.length === 0">
        <PlaceholderMap text="暂无考试噢~" />
      </div>
      <div class="study-item" v-for="(item,index) in personExamList" :key="index">
        <div class="study-item-left">
          <div class="img-box">
            <img :src="item.cover" alt="" />
          </div>
          <div class="info-box">
            <div class="info-box-t">
              {{item.examName}}
            </div>
            <div class="info-box-m">{{item.introduce}}</div>
            <div class="info-box-b">
              <div>
                <span :class="item.examStatusValue=='缺考'?'redBg basebg':(item.examStatusValue=='待考'?'greyBg basebg':(item.examStatusValue=='已考'?'blueBg basebg':''))">{{item.examStatusValue}}</span>
                考试成绩<span style="color: #0089ff">{{item.mark||'0'}}</span>分
              </div>
              <div class="line-box"></div>
              <div>
                考试时间 <span style="white-space: pre-wrap;">{{item.startTime}} - {{item.endTime}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="study-item-right">
          <el-button type="primary" plain @click="_startExamBtn(item)" v-if="item.examStatusValue=='待考'" :style="{'--lock-btn': $store.state.settings.theme}">开始考试</el-button>
          <el-button type="primary" plain @click="_examResultBtn(item)" v-if="item.examStatusValue=='已考'" :style="{'--lock-btn': $store.state.settings.theme}">考试结果</el-button>
        </div>
      </div>
    </div>
    <!-- 考试结果列表---开始 -->
      <el-pagination
        class="fx-row-end"
        style="padding-top:10px;"
        @size-change="_handleSizeChange"
        @current-change="_handleCurrentChange"
        :current-page="searchForm.page"
        :page-sizes="[5, 10, 20, 30, 40]"
        :page-size="searchForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="examResultTotal">
      </el-pagination>
    <!-- 考试结果列表---结束 -->
  </div>
</template>

<script>
// import { getPersonnalExamListApi } from '@/api/examApi'
import PlaceholderMap from '@/components/placeholderMap/index.vue'
import { getUserExamArrangeListApi } from '@/api/study'
export default {
  components: {
    PlaceholderMap
  },
  data () {
    return {
      personExamList: [],
      examResultTotal: 0,
      searchForm: {
        page: 1,
        pageSize: 10
      }

    }
  },
  created () {
    console.log()
    this._getPersonnalExamList()
  },
  mounted () {

  },
  methods: {
    // 获取考试结果列表
    _getPersonnalExamList () {
      // getPersonnalExamListApi().then((res) => {
      //   if (res.code === 200) {
      //     this.personExamList = res.data
      //   }
      // })
      getUserExamArrangeListApi({ ...this.searchForm }).then((res) => {
        if (res.code === 200) {
          this.personExamList = res.object.results
          this.examResultTotal = res.object.total
        }
      })
    },
    // 分页
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      this._getPersonnalExamList()
    },
    _handleCurrentChange (val) {
      this.searchForm.page = val
      this._getPersonnalExamList()
    },
    // 开始考试
    _startExamBtn (item) {
      this.$router.push({
        path: '/examinationStartDetail',
        query: {
          id: item.examInfoId
        }
      })
    },
    // 考试结果
    _examResultBtn (row) {
      // studyEndFlag学习计划结束标志，如果是true说明学习计划结束，不允许查看考试详情---考试结束页面
      console.log(row, 'hhhhhhh---')
      if (row.studyEndFlag) {
        this.$router.push({
          path: '/endOfExamination',
          query: {
            examInfoId: row.examInfoId,
            employeeId: this.$store.state.userList.userId,
            typeValue: 'examResult'
          }
        })
      } else {
        // 学习计划未设置或者未开始
        if (row.correctStatusValue === '批阅中') {
          this.$router.push({
            path: '/endOfExamination',
            query: {
              examInfoId: row.examInfoId,
              employeeId: this.$store.state.userList.userId
            }
          })
        } else {
          this.$router.push({
            path: '/studentExamResultDetail',
            query: {
              examInfoId: row.examInfoId,
              employeeId: this.$store.state.userList.userId,
              typeValue: 'examResult'
            }
          })
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>
  .container-warp{
    padding: 16px 24px;
    min-height:790px;
    overflow: hidden;
    background-color:#ffffff;
    border-radius: 8px;
    box-sizing: border-box;
    overflow-y: scroll;
  }
  .study-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  margin-bottom: 16px;
  background: linear-gradient(to bottom, #f0f8ff, #ffffff);
  border: 1px solid #e7f4ff;
  border-radius: 8px;
  .study-item-left {
    display: flex;
    .img-box {
      margin-right: 12px;
      width: 120px;
      height: 90px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
    .info-box {
      .info-box-t {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
      .info-box-m {
        margin: 8px 0 16px;
        font-size: 14px;
        color: #666666;
      }
      .info-box-b {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #666666;
        .line-box{
          margin: 0 12px;
          width: 1px;
          height: 16px;
          background-color: #DCDCDC;
        }
      }
    }
  }
  .basebg{
    display: inline-block;
    padding: 0px 2px;
    border-radius: 2px;
    font-size: 12px;
  }
  .redBg{
    background: #FFF2F1;
    color: #F04134;
    border: 1px solid #F04134;
  }
  .greyBg{
    background: #F5F8FF;
    color: #75849B;
    border: 1px solid #75849B;
  }
  .blueBg{
    background: #F2F8FF;
    color: #0089FF;
    border: 1px solid #0089FF;

  }
}
.exam-content{
  height: 720px!important;
  overflow-y: scroll;
}
</style>
